export default class SearchController {
    constructor($scope, $http, $q, translateService) {
        this.$scope = $scope;
        this.$http = $http;
        this.$q = $q;
        this.translate = translateService;

        this.phrase = '';
    }

    $onInit() {
        this.search();
    }

    search() {
        if (this.phrase !== '') {
            let ctrl = this;

            const deferred = this.$q.defer();

            this.$http.post('/ajax/domains/search', {phrase: this.phrase}).then(
                (response) => {
                    deferred.resolve({});
                },
                (response) => {
                    deferred.reject(ctrl.translate.t('domains.unexpected_error_occurred'));
                }
            );

            return deferred.promise;
        }
    }
}

SearchController.$inject = ['$scope', '$http', '$q', 'translateService'];
