import SpinnerView from '../views/spinner.view.html?raw';

export default class SpinnerDirective {
    constructor(spinnerService) {
        this.spinnerService = spinnerService;

        this.restrict = 'E';
        this.scope = {
            name: '@'
        };
        this.transclude = true;
        this.template = SpinnerView;
    }

    link(scope, elm, attrs) {
        scope.active = false;

        var parent = elm.parent();
        var parentPosition = parent.position;

        var spinnerContext = {
            show: () => {
                scope.active = true;
            },
            close: () => {
                scope.active = false;
            }
        }

        if (!parentPosition || parentPosition === 'static' || parentPosition === '') {
            parent.css('position', 'relative');
        }

        this.spinnerService.register(scope.name, spinnerContext);
    }

    static directiveFactory(spinnerService) {
        return new SpinnerDirective(spinnerService);
    }
}
