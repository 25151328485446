// jQuery Core
import $ from 'jquery';
window.$ = window.jQuery = $;

// Boostrap Core
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

// ElasticLunr search
import elasticlunr from './vendor/elasticlunr.cjs';

// Mark text
import * as markjs from 'mark.js';

// Angular Core
import angular from 'angular';

// Angular Libraries
import angularNgSanitize from 'angular-sanitize';
import angularNgAnimate from 'angular-animate';
import * as angularjsToast from 'angularjs-toast';
import angularUiBootstrap from 'ui-bootstrap4';

// Angular Controllers
import ErrorsController from './angular/controllers/errors';
import SearchController from './angular/controllers/search';
import CartItemsTableController from './angular/controllers/cartItemsTable';
import CheckoutController from './angular/controllers/checkout';
import FaqController from './angular/controllers/faqController';

// Angular Services
import DomainsService from './angular/services/domains.service';
import SharedDataService from './angular/services/sharedData.service';
import TranslateService from './angular/services/translate.service';
import SpinnerService from './angular/services/spinner.service';

// Angular Components
import DomainCheckPriceComponent from './angular/components/domainCheckPrice.component';
import DomainsSearchComponent from './angular/components/domainsSearch.component';
import DomainsFoundComponent from './angular/components/domainsFound.component';
import GalleryComponent from './angular/components/gallery.component';
import TldSelectorComponent from './angular/components/tldSelector.component';
import MapTldSelectorComponent from './angular/components/mapTldSelector.component';
import Add2cart from './angular/components/add2cart.component';
import MiniCart from './angular/components/miniCart.component';
import CookieWallComponent from './angular/components/cookieWall.component';

// Angular Directives
import SpinnerDirective from './angular/directives/spinner';
import WorldMapDirective from './angular/directives/worldMap';
import BsTooltipDirective from './angular/directives/bsTooltip';

// Angular Filters
import TranslateFilter from './angular/filters/translate.filter';

// Angular Dependencies
const dependencies = [
    angularNgSanitize,
    angularNgAnimate,
    'angularjsToast',
    angularUiBootstrap
];

// Angular App
const letsDomainsApp = angular.module('letsdomains', dependencies);

// Angular App Config
letsDomainsApp.config(['$httpProvider', '$animateProvider', function ($httpProvider, $animateProvider) {
    $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    const interceptor = [
        '$q',
        function($q){
            return {
                'request': function (config) {
                    if (config.url.indexOf('api') !== -1) {
                        const currentLocale = document.documentElement.lang;

                        config.url = (currentLocale !== 'en' ? currentLocale : '') + config.url;
                    }

                    return config;
                }
            };
        }
    ];

    $httpProvider.interceptors.push(interceptor);
    $animateProvider.classNameFilter(/\banimate\b/);
}]);

// Use Angular Resources
letsDomainsApp.controller('ErrorsController', ErrorsController);
letsDomainsApp.controller('SearchController', SearchController);
letsDomainsApp.controller('CartItemsTableController', CartItemsTableController);
letsDomainsApp.controller('CheckoutController', CheckoutController);
letsDomainsApp.controller('FaqController', FaqController);

letsDomainsApp.service('domainsService', DomainsService);
letsDomainsApp.service('sharedData', SharedDataService);
letsDomainsApp.service('translateService', TranslateService);
letsDomainsApp.service('spinnerService', SpinnerService);

letsDomainsApp.component('domainCheckPrice', DomainCheckPriceComponent);
letsDomainsApp.component('domainsFound', DomainsFoundComponent);
letsDomainsApp.component('domainsSearch', DomainsSearchComponent);
letsDomainsApp.component('tldSelector', TldSelectorComponent);
letsDomainsApp.component('gallery', GalleryComponent);
letsDomainsApp.component('mapTldSelector', MapTldSelectorComponent);
letsDomainsApp.component('add2cart', Add2cart);
letsDomainsApp.component('miniCart', MiniCart);
letsDomainsApp.component('cookieWall', CookieWallComponent);

BsTooltipDirective.$inject = ['translateService'];

letsDomainsApp.directive('spinner', ['spinnerService', SpinnerDirective.directiveFactory]);
letsDomainsApp.directive('worldMap', ['domainsService', WorldMapDirective.directiveFactory]);
letsDomainsApp.directive('bsTooltip', ['translateService', BsTooltipDirective.directiveFactory]);

letsDomainsApp.filter('t', TranslateFilter);

// Constants
letsDomainsApp.constant('elasticlunr', elasticlunr);
letsDomainsApp.constant('markjs', markjs);

// jQuery Scripts ready
$(document).ready(function () {
    registerPasswordValidity();
    addGoogleAnalyticsEvents();
    initMap();
    resizeIframes()
});

$('.target-dropdown').on('click', (e) => {
    if ($(window).width() <= 991) {
        $(e.target).siblings().toggleClass('show-dropdown');
    }
});

$(window).on('resize', function () {
    if ($(window).width() > 991) {
        $('.dropdown-content').removeClass('show-dropdown');
    }
});

$('.menu-btn ').on('click', () => {
    $('.header').toggleClass('show-mob-menu');
});

$('.switch-control').on('change', function() {
    switch ($(this).prop('checked')) {
        case true:
            $(this).parent().parent().addClass('switch-on');
            $(this).parent().parent().removeClass('switch-off');
            break;
        default:
            $(this).parent().parent().addClass('switch-off');
            $(this).parent().parent().removeClass('switch-on');
    }
});

function registerPasswordValidity() {

    let registerForm = document.forms['register-form'];
    if (!registerForm) {
        return;
    }

    let passwordInput = registerForm.password;
    let passwordInputRestrictionLowercaseLetter = document.getElementById('passwordHelp-lowercaseLetter');
    let passwordInputRestrictionUppercaseLetter = document.getElementById('passwordHelp-uppercaseLetter');
    let passwordInputRestrictionOneNumber = document.getElementById('passwordHelp-oneNumber');
    let passwordInputRestrictionMinimumCharacters = document.getElementById('passwordHelp-minimumCharacters');


    passwordInput.onfocus = function() {
        document.getElementById('passwordHelp').style.display = 'block';
    };

    passwordInput.onblur = function() {
        document.getElementById('passwordHelp').style.display = 'none';
    };

    passwordInput.onkeyup = function() {
        let lowercaseLetters = /[a-z]/g;
        if (passwordInput.value.match(lowercaseLetters)) {
            passwordInputRestrictionLowercaseLetter.classList.remove('invalid');
            passwordInputRestrictionLowercaseLetter.classList.add('valid');
        } else {
            passwordInputRestrictionLowercaseLetter.classList.remove('valid');
            passwordInputRestrictionLowercaseLetter.classList.add('invalid');
        }

        let uppercaseLetters = /[A-Z]/g;
        if (passwordInput.value.match(uppercaseLetters)) {
            passwordInputRestrictionUppercaseLetter.classList.remove('invalid');
            passwordInputRestrictionUppercaseLetter.classList.add('valid');
        } else {
            passwordInputRestrictionUppercaseLetter.classList.remove('valid');
            passwordInputRestrictionUppercaseLetter.classList.add('invalid');
        }

        let numbers = /[0-9]/g;
        if (passwordInput.value.match(numbers)) {
            passwordInputRestrictionOneNumber.classList.remove('invalid');
            passwordInputRestrictionOneNumber.classList.add('valid');
        } else {
            passwordInputRestrictionOneNumber.classList.remove('valid');
            passwordInputRestrictionOneNumber.classList.add('invalid');
        }

        if (passwordInput.value.length >= 8) {
            passwordInputRestrictionMinimumCharacters.classList.remove('invalid');
            passwordInputRestrictionMinimumCharacters.classList.add('valid');
        } else {
            passwordInputRestrictionMinimumCharacters.classList.remove('valid');
            passwordInputRestrictionMinimumCharacters.classList.add('invalid');
        }
    };
}

function addGoogleAnalyticsEvents() {
    $('.ga-event').click(function() {
        let event = $(this).data('event');
        let params = $(this).data('params');
        let data = {event: event, ...params};

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(data);
    });

    document.addEventListener('copy', async (event)=> {
        const text = await navigator.clipboard.readText();

        window.dataLayer = window.dataLayer || [];

        if (text.match(/71 718 13 10/gm)) {
            window.dataLayer.push({event: 'clipboard_Phone'});
        } else if (text.match(/info@letsdomains\.com/m)) {
            window.dataLayer.push({event: 'clipboard_Email'});
        }
    });
}

let infoWindow = null;

function placeMarker(map, lat, lng, txt) {
    let markerOptions =  {
        position: new google.maps.LatLng(lat,lng),
        map: map
    }
    let marker = new google.maps.Marker(markerOptions);
    marker.txt=txt;

    google.maps.event.addListener(marker,"click",function() {
        infoWindow.setContent(marker.txt);
        infoWindow.open(map,marker);
    });
    return marker;
}

function initMap()   {

    if (!document.getElementById('footerMap') || !google) {
        return;
    }
    infoWindow = new google.maps.InfoWindow();

    let mapCenter = new google.maps.LatLng(53.553511, 16.999898);
    let mapOptions = {
        zoom: 5,
        center: mapCenter,
        styles: [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"poi.park","elementType":"all","stylers":[{"visibility":"on"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"transit.line","elementType":"geometry","stylers":[{"visibility":"on"}]},{"featureType":"transit.station.airport","elementType":"all","stylers":[{"visibility":"on"}]},{"featureType":"transit.station.airport","elementType":"geometry","stylers":[{"visibility":"on"}]},{"featureType":"transit.station.bus","elementType":"all","stylers":[{"visibility":"on"}]},{"featureType":"transit.station.bus","elementType":"geometry","stylers":[{"visibility":"on"}]},{"featureType":"transit.station.rail","elementType":"all","stylers":[{"visibility":"on"}]},{"featureType":"transit.station.rail","elementType":"geometry","stylers":[{"visibility":"on"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#6e769e"},{"visibility":"on"}]}],
        mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    let map = new google.maps.Map(document.getElementById('footerMap'), mapOptions);

    let marker1 = placeMarker(map, 51.1082119, 17.0274280,
        '<img src="/build/frontend/img/logo-main-simple-slogan.svg?v=202402140" alt="Let\'s Domains logo" style="width: 96px;height: 30px; margin-bottom: 5px;" /><br />' +
        '<strong>ul. Stacyjna 1/63 </strong><br />' +
        'WROCŁAW');
    google.maps.event.trigger(marker1,'click');
}

function resizeIframes() {
    const iframes =   document.getElementsByClassName('fit-content-height');
    if (iframes.length === 0) {
        return;
    }
    setInterval(() => {
        for (const iframe of iframes) {
            const iFrameHeight = iframe.contentWindow.document.body.scrollHeight;
            iframe.style.height = iFrameHeight + 'px';
        }
    }, 500);
}
