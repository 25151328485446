export class CartItem {
    constructor(element) {
        this.rowId = 'rowId' in element ? element.rowId : null;
        this.id = 'id' in element ? element.id : null;
        this.name = 'name' in element ? element.name : null;
        this.quantity = 'quantity' in element ? element.quantity : 1;
        this.options = 'options' in element ? element.options : [];
        this.price = 'price' in element ? this.parsePrice(element.price) : null;
        this.priceTax = 'priceTax' in element ? this.parsePrice(element.priceTax) : null;
        this.subtotal = 'subtotal' in element ? this.parsePrice(element.subtotal) : null;
        this.total = 'total' in element ? element.total : null;
        this.tax = 'tax' in element ? element.tax : null;
        this.taxTotal = 'taxTotal' in element ? element.taxTotal : null;
        this.isPromo = 'isPromo' in element ? element.isPromo : false;
        this.promoPrice = 'promoPrice' in element ? this.parsePrice(element.promoPrice) : null;
        this.promoPriceTax = 'promoPriceTax' in element ? this.parsePrice(element.promoPriceTax) : null;
        this.promoSubtotal = 'promoSubtotal' in element ? this.parsePrice(element.promoSubtotal) : null;
        this.promoTotal = 'promoTotal' in element ? this.parsePrice(element.promoTotal) : null;
        this.promoTax = 'promoTax' in element ? this.parsePrice(element.promoTax) : null;
        this.promoTaxTotal = 'promoTaxTotal' in element ? this.parsePrice(element.promoTaxTotal) : null;
        this.associate = 'associate' in element ? element.associate : null;
        this.canChangeQuantity = 'canChangeQuantity' in element ? element.canChangeQuantity : true;
        this.canChangePeriod = 'canChangePeriod' in element ? element.canChangePeriod : true;
        this.canDelete = 'canDelete' in element ? element.canDelete : true;
        this.period = 'period' in element ? element.period : 1;
    }

    parsePrice(price) {
        const parsedPrice = /[0-9]+([,.][0-9]+)?/gm.exec(price);

        if (parsedPrice[0]) {
            parsedPrice[0] = parseFloat(parsedPrice[0].replace(',', '.'));
            return parsedPrice[0];
        }

        return price;
    }
}
