export default class ErrorsController {
    constructor($scope) {
        this.$scope = $scope;
        this.alerts = [];
    }

    close(index) {
        this.alerts.splice(index, 1);
    }
}

ErrorsController.$inject = ['$scope'];
