import MapTldSelectorView from '../views/mapTldSelector.view.html?raw';

class MapTldSelectorController {
    constructor(sharedData, domainsService) {
        this.sharedData = sharedData;
        this.domainsService = domainsService;
        this.limit = 30;
        this.showAll = false;
        this.activeRegion = 'europe';
        this.countryFlagExceptions = {
            'eu.com': 'eu',
            'ею': 'eu',
            'ευ': 'eu'
        };
    }

    $onInit() {
    }

    $onChanges() {

    }

    getFlagCode(tld) {
        if (tld.country !== null && tld.country.iso_code !== null) {
            return tld.country.iso_code.toLowerCase();
        }

        if (this.countryFlagExceptions.hasOwnProperty(tld.extension_idn) === true) {
            return this.countryFlagExceptions[tld.extension_idn].toLowerCase();
        }

        return tld.extension_idn.toLowerCase();
    }

    select(name) {
        this.showAll = false;
        this.activeRegion = name;
    };

    toggleShow() {
        this.showAll = !this.showAll;
    }

    toggleTld(tld) {
        const indexCheck = this.sharedData.selectedTlds.indexOf(tld.extension);

        if (indexCheck > -1) {
            this.sharedData.selectedTlds.splice(indexCheck, 1);
        } else {
            this.sharedData.selectedTlds.unshift(tld.extension);
        }
    }
}

let mapTldSelectorComponent = {
    bindings: {
        activeRegion: '@'
    },
    template: MapTldSelectorView,
    controller: MapTldSelectorController
};

MapTldSelectorController.$inject = ['sharedData', 'domainsService'];
export default mapTldSelectorComponent;
