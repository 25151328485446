import TldSelectorView from '../views/tldSelector.view.html?raw';

class TldSelectorController {
    constructor(sharedData, domainsService) {
        this.sharedData = sharedData;
        this.domainsService = domainsService;
        this.defaultLimit = null;
    }

    $onInit() {
        this.defaultLimit = this.limit;
    }

    $onChanges() {

    }

    get hasTlds() {
        return this.sharedData.tlds.find(tld => tld.type === this.type);
    }

    toggleTld(tld) {
        const indexCheck = this.sharedData.selectedTlds.indexOf(tld.extension_idn);

        if (indexCheck > -1) {
            this.sharedData.selectedTlds.splice(indexCheck, 1);
        } else {
            this.sharedData.selectedTlds.unshift(tld.extension_idn);
        }
    }
}

let tldSelectorComponent = {
    bindings: {
        type: '<',
        limit: '<',
        title: '@'
    },
    template: TldSelectorView,
    controller: TldSelectorController
};

TldSelectorController.$inject = ['sharedData', 'domainsService'];

export default tldSelectorComponent;
