import * as punycode from 'punycode';

export default class DomainsService {
    constructor($http) {
        this.$http = $http;
        this.punycode = punycode;

        this.regex = {};

        this.regex.domainFullRegex = /^\b((?=[a-zA-Z0-9-]{3,63}\.)(xn---*)?[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.)+([a-zA-Z]{3,63}|xn--[a-zA-Z0-9]{4,63})\b$/;
        this.regex.domainFullMultipline = /\b((?=[a-zA-Z0-9-]{3,63}\.)(xn---*)?[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.)+([a-zA-Z]{2,63}|xn--[a-zA-Z0-9]{4,63})\b/g;
        this.regex.domainAutocomplete = /^\b((?=[a-zA-Z0-9-]{3,63}\.)(xn---*)?[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.)+([a-zA-Z]{1,63}|xn--[a-zA-Z0-9]{2,63})\b$/;
        this.regex.domainR = /^\b[a-zA-Z0-9-]{3,63}\b$/;
    }

    getAvailability(phrase, extension) {
        return this.$http.post('/api/domains/availability', { phrase: phrase, extension: extension });
    }

    getExtensionsList2search() {
        return this.$http.get('/api/domains/extensions');
    }

    getExtensionsListByRegions() {
        if (typeof window.domainsByRegions !== 'undefined') {
            return window.domainsByRegions;
        } else {
            return {
                'africa': {},
                'asia': {},
                'australia': {},
                'europe': {},
                'middle-east': {},
                'north-america': {},
                'south-america': {}
            }
        }
    }
}

DomainsService.$inject = ['$http'];
