import Add2cartView from '../views/add2cart.view.html?raw';
import { CartItem } from '../common/common';

class Add2cartController {
    constructor(sharedData) {
        this.sharedData = sharedData;
        this.cartItem = null;
    }

    $onChanges() {
        const element = {
            id: this.id,
            name: this.name,
            quantity: this.quantity,
            options: this.options,
            associate: this.associate,
            isPromo: this.isPromo,
            price: this.prices.price,
            promoPrice: this.prices.promo_price
        };
        this.cartItem = new CartItem(element);
    }

    add() {
        this.sharedData.cart.add(this.cartItem);

        const cartItem = this.cartItem;

        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            event: 'add_to_cart',
            currency: window.currency.iso4217,
            value: cartItem.isPromo ? cartItem.promoPrice : cartItem.price,
            items: [{
                item_id: cartItem.associate === 'Domain' ? cartItem.options.extension : cartItem.name,
                item_name: cartItem.name,
                currency: window.currency.iso4217,
                discount: cartItem.isPromo ? cartItem.price - cartItem.promoPrice : 0,
                price: cartItem.isPromo ? cartItem.promoPrice : cartItem.price,
                quantity: 1
            }]
        });
    }

    checkIfInCart() {
        const check = this.sharedData.cart.checkIfInCart(this.cartItem);
        if (check) {
            this.cartItem = check;
        }

        return check;
    }

    remove() {
       this.sharedData.cart.remove(this.cartItem);
    }
}

let add2cartComponent = {
    bindings: {
        id: '<',
        name: '<',
        quantity: '<',
        options: '<',
        associate: '<',
        isPromo: '<',
        prices: '<',
    },
    template: Add2cartView,
    controller: Add2cartController
};

Add2cartController.$inject = ['sharedData'];

export default add2cartComponent;
