import DomainsFoundView from '../views/domainsFound.view.html?raw';

class DomainFoundController {
    constructor($scope, sharedData, domainsService, spinnerService) {
        this.$scope = $scope;
        this.sharedData = sharedData;
        this.domainsService = domainsService;
        this.spinnerService = spinnerService;
        this.paramTld = null;
        this.paramName = null;
        this.lastSearchParam = '';
    }

    $onInit() {
        this.spinnerService.show('tldSelector');

        this.getExtensionsList();

        this.$scope.$watch(() => this.sharedData.searchParam,(newValue, oldValue) => {
            this.checkDomains();
        });
    }

    $onChanges() {

    }

    checkDomains() {
        if (!this.sharedData.searchParam) {
            return;
        }

        if (this.lastSearchParam !== this.sharedData.searchParam) {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                event: 'view_search_results',
                searchTerm: this.sharedData.searchParam
            });
            this.lastSearchParam = this.sharedData.searchParam;
        }

        if (this.sharedData.tlds.length === 0) {
            return;
        }

        if (this.sharedData.searchParam.indexOf('.') !== -1) {
            let paramArray = this.sharedData.searchParam.split('.');
            let newParamName = paramArray.shift();

            if (this.paramName !== newParamName) {
                this.paramName = angular.copy(newParamName);
            }

            this.paramTld = paramArray.join('.');

            if (!this.checkExtension(this.paramTld)) {
                return;
            }

            const tldIndexCheck = this.sharedData.selectedTlds.indexOf(this.paramTld);
            if (tldIndexCheck >= 0) {
                this.sharedData.selectedTlds.splice(tldIndexCheck, 1);
            }

            this.sharedData.selectedTlds.unshift(this.paramTld);
        } else {
            this.paramName = angular.copy(this.sharedData.searchParam);
        }
    }

    checkExtension(extension) {
        return (this.sharedData.tlds.filter((item) => {
            return item.extension_idn === extension;
        }).length > 0);
    }

    getExtensionIdn(extension) {
        let items = this.sharedData.tlds.filter((item) => {
            return item.extension === extension;
        });

        if (items.length > 0) {
            return items[0].extension_idn;
        }

        return extension;
    }

    getExtensionsList() {
        let ctrl = this;

        this.domainsService.getExtensionsList2search().then((response) => {
            if (response.status === 200) {
                ctrl.sharedData.tlds = response.data.data;

                this.spinnerService.close('tldSelector');

                ctrl.checkDomains();
            }
        });
    }
}

let domainFoundComponent = {
    bindings: {},
    template: DomainsFoundView,
    controller: DomainFoundController
};

DomainFoundController.$inject = ['$scope', 'sharedData', 'domainsService', 'spinnerService'];

export default domainFoundComponent;
