import DomainsSearchView from '../views/domainsSearch.view.html?raw';

class DomainSearchController {
    constructor(sharedData) {
        this.sharedData = sharedData;
    }

    $onInit() {
        this.extensions = JSON.parse(this.extensions);
        if (this.extensions && this.extensions.length > 0) {
            this.sharedData.selectedTlds = this.extensions;
        } else if(this.additionalSelectedTlds && JSON.parse(this.additionalSelectedTlds)) {
            const additionalSelectedTlds = JSON.parse(this.additionalSelectedTlds);
            this.sharedData.selectedTlds = this.sharedData.selectedTlds.concat(additionalSelectedTlds);
        }

        this.search(false);
    }

    $onChanges() {

    }

    search(sendGaEvent = true) {
        if (sendGaEvent) {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                event: 'button_SearchDomain',
                buttonLocation: 'angularComponent'
            });
        }

        this.sharedData.searchParam = this.phrase.toLowerCase();
    }
}

let domainSearchComponent = {
    bindings: {
        phrase: '<',
        extensions: '<',
        additionalSelectedTlds: '<'
    },
    template: DomainsSearchView,
    controller: DomainSearchController
};

DomainSearchController.$inject = ['sharedData'];
export default domainSearchComponent;
