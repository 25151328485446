class Common {
    constructor($http, toast) {
        this.$http = $http;
        this.toast = toast;

        this.currency = {
            symbol_placement: null,
            symbol: null
        };

        this.load();
    }

    load() {
        const _this = this;

        this.$http.get('/api/currency/info').then((response) => {
            this.currency = response.data.data;
        }, (response) => {
            _this.toast.create({
                message: response.data.message,
                className: 'alert-danger'
            });
        });
    }

    displayPrice(value) {
        return (this.currency.symbol_placement === 'before' ? this.currency.symbol : '') + value.toFixed(2).replace('.', ',').replace(/\B(?=(?:\d{3})+(?!\d))/g, ' ') + (this.currency.symbol_placement === 'after' ? this.currency.symbol : '');
    }
}

import { CartItem } from "./../common/common";

class Cart {
    constructor($http, toast) {
        this.$http = $http;
        this.toast = toast;

        this.items = [];

        this.loadFromDb();
    }

    checkIfInCart(item2check) {
        const items = this.items.filter(item => {
            return (item.id === item2check.id && item.associate === item2check.associate);
        });

        if (items.length > 0) {
            item2check = items[0];

            return items[0];
        }

        return false;
    }

    add(item) {
        const _this = this;

        return this.$http.post('/api/cart/add', item).then((response) => {
            if (angular.isArray(response.data.data)) {
                this.items = [];

                response.data.data.forEach(element => {
                    const cartItem = new CartItem(element);

                    this.items.push(cartItem);
                });
            }
        }, (response) => {
            _this.toast.create({
                message: response.data.message,
                className: 'alert-danger'
            });
        });
    }

    remove(item) {
        return this.$http.post('/api/cart/remove', item).then((response) => {
            const itemIndex = this.items.indexOf(item);

            if (itemIndex > -1) {
                this.items.splice(itemIndex, 1);
            }
        });
    }

    loadFromDb() {
        const _this = this;

        return this.$http.get('/api/cart/content').then((response) => {
            if (angular.isArray(response.data.data)) {
                response.data.data.forEach(element => {
                    const cartItem = new CartItem(element);

                    this.items.push(cartItem);
                });
            }
        }, (response) => {
            _this.toast.create({
                message: response.data.message,
                className: 'alert-danger'
            });
        });
    }
}

export default class SharedDataService {
    constructor($http, toast) {
        'ngInject'

        this.common = new Common($http, toast);
        this.tlds = [];
        this.selectedTlds = [
            'com',
            'tv',
            'net',
            'info'
        ];
        this.searchParam = null;
        this.cart = new Cart($http, toast);
    }
}

SharedDataService.$inject = ['$http', 'toast'];
