import GalleryView from '../views/gallery.view.html?raw';

class GalleryController {
    constructor($scope, $document, $element, $http, $location, $timeout, $window) {
        this.$scope = $scope;
        this.$document = $document;
        this.$element = $element;
        this.$http = $http;
        this.$timeout = $timeout;
        this.$window = $window;
        this.currentImage = null;
        this.imagesTransitions = [];
        this.loadingImage = false;
        this.direction = '';
        this.display = {min: 0, max: 0};
        this.currentOffset = 0;
        this.imagesInRow = null;
        this.modalClass = 'hidden';
        this.isShown = false;
        this.window = $(window);

        this.window.bind('resize', () => this.setImagesInRow(() => {
            this.$scope.$apply();
        }));
        $document.bind('keyup', event => this.onKeyPress(event));


        this.setImagesInRow();
    }

    $onInit() {
        if (!this.proportions) {
            this.proportions = '75%';
        }
        this.images = this.images.map(imageData => new GalleryImage(imageData));
        this.modalClass = 'collapsed';
        this.display = {min: 1, max: this.imagesInRow};
    }

    startDrag($mouseEvent) {
    }

    stopDrag($mouseEvent) {
    }

    setImagesInRow(onChangeCallback) {

        let oldImagesInRow = this.imagesInRow;
        if (this.window.width() < 500) {
            this.imagesInRow = 3;
        } else if (this.window.width() < 700) {
            this.imagesInRow = 5;
        } else if (this.window.width() < 1000) {
            this.imagesInRow = 8;
        } else {
            this.imagesInRow = 10;
        }

        if(oldImagesInRow !== this.imagesInRow) {
            if(onChangeCallback) {
                onChangeCallback();
            }
        }
    }

    showModal() {
        if (this.isShown) {
            return;
        }
        this.currentImage = null;
        this.imagesTransitions = [];
        this.modalClass = 'collapsed';
        this.$timeout(() => {
            this.isShown = true;
            this.modalClass = 'visible';
        }, 200);
    }

    closeModal() {
        if (!this.isShown) {
            return;
        }
        this.modalClass = 'collapsed'
        this.$timeout(() => {
            this.isShown = false;
            this.modalClass = '';
        }, 600);
    }

    onKeyPress(event) {
        switch (event.keyCode) {
            case 39: // ->
                this.nextImage();
                break;
            case 37: // <-
                this.prevImage();
                break;
            case 27: // ESC
                this.closeModal();
                break;
        }
    }

    setCurrentImage(image) {
        this.showModal();

        let direction = 'left';

        if (!this.currentImage) {
            direction = 'center';
        } else if (this.images.indexOf(image) < this.images.indexOf(this.currentImage)) {
            direction = 'right';
        }

        this.displayImage(image, direction);
    }

    displayImage(image, direction) {
        if (this.currentImage === image) {
            return false;
        }

        let index = this.imagesTransitions.indexOf(image);

        if (index > -1) {
            this.imagesTransitions.splice(index, 1);
        }

        this.direction = direction;
        image.position = direction === 'right' ? 'left' : (direction === 'left' ? 'right' : 'center');

        this.imagesTransitions.push(image);
        this.loadImage(image, () => {
            let oldImage = this.currentImage;

            this.currentImage = image;
            image.position = 'center';

            if (!oldImage) {
                return;
            }

            oldImage.position = direction;

            image.timeout = this.$timeout(() => {
                if (oldImage === this.currentImage) {
                    return;
                }
                let index = this.imagesTransitions.indexOf(oldImage);
                if (index > -1) {
                    this.imagesTransitions.splice(index, 1);
                }
            }, 1000);
        });
    }

    prevImage() {
        if (this.hasPrevImage) {
            let prevImage = this.images[this.currentImageIndex - 1];
            this.displayImage(prevImage, 'right');
        }
    }

    nextImage() {
        if (this.hasNextImage) {
            let nextImage = this.images[this.currentImageIndex + 1];
            this.displayImage(nextImage, 'left');
        }
    }

    loadImage(image, callback) {
        this.loadingImage = true;
        return this.$http.get(image.full).then(() => {
            this.loadingImage = false;
            callback();
        });
    }

    get sliderOffset() {
        let offset = 0;

        if (this.images.length < this.imagesInRow) {
            offset = (1 - (this.images.length / this.imagesInRow)) / 2;
            return (offset * 100) + '%';
        }

        if (this.currentImageIndex + 1 >= this.display.max) {
            this.display.max = this.currentImageIndex + 2 <= this.images.length ? this.currentImageIndex + 2 : this.images.length;
            this.display.min = this.display.max - this.imagesInRow;
            this.currentOffset = ((-this.display.min / this.imagesInRow) * 100) + '%';
        } else if (this.currentImageIndex - 1 <= this.display.min) {
            this.display.min = (this.currentImageIndex - 1) > 0 ? this.currentImageIndex - 1 : 0;
            this.display.max = this.display.min + this.imagesInRow;
            this.currentOffset = ((-this.display.min / this.imagesInRow) * 100) + '%';
        }

        return this.currentOffset;
    }

    get currentImageIndex() {
        return this.images.indexOf(this.currentImage);
    }

    get hasNextImage() {
        if (!this.currentImage) {
            return false;
        }
        return this.currentImageIndex + 1 < this.images.length;

    }

    get hasPrevImage() {
        if (!this.currentImage) {
            return false;
        }
        return this.currentImageIndex > 0;
    }

    get thumbnailsColumnWidth() {
        return (100 / this.imagesInRow) + '%';
    }
}

let galleryComponent = {
    bindings: {
        images: '<',
        proportions: '<'
    },
    template: GalleryView,
    controller: GalleryController
};

class GalleryImage {
    constructor(data) {
        this.thumb = data.thumb;
        this.full = data.full;
        this.position = data.position ? data.position : 'center';
    }
}

GalleryController.$inject = ['$scope', '$document', '$element', '$http', '$location', '$timeout', '$window'];

export default galleryComponent;
