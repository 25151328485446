import DomanCheckPriceView from '../views/domainCheckPrice.view.html?raw';

class DomainCheckPriceController {
    constructor(domainsService, translateService) {
        this.domainsService = domainsService;
        this.translate = translateService;
        this.gettingData = false;
        this.prices = null;
        this.status = null;
        this.msg = null;
        this.canAdd = false;
        this.options = [];
        this.add2cartReference = null;
    }

    $onInit() {
    }

    $onChanges() {
        this.getAvailability();
    }

    getAvailability() {
        let ctrl = this;
        ctrl.gettingData = true;

        this.domainsService.getAvailability(this.name, this.extension).then((response) => {
            ctrl.gettingData = false;

            if (response.status === 200) {
                ctrl.prices = response.data.data.prices;
                ctrl.status = response.data.data.status.status;
                ctrl.msg = this.getStatusMessage(response.data.data.status.status) ?? response.data.data.status.message;
                ctrl.canAdd = response.data.data.status.available_domain;
                ctrl.options = response.data.data.options;
                ctrl.restrictions = response.data.data.restrictions;
                ctrl.registrationInfo = response.data.data.registration_info;
            } else {
                ctrl.msg = ctrl.translate.t('domains.unexpected_error_occurred_please_contact_lets_domains_technical_support');
            }
        }, (response) => {
            ctrl.gettingData = false;

            ctrl.msg = ctrl.translate.t('domains.unexpected_error_occurred_please_contact_lets_domains_technical_support');
        });
    }

    getStatusMessage(statusCode) {
        switch(statusCode) {
            case 9071:
                return this.translate.t('domains.domain_is_blocked');
            case 9072:
                return this.translate.t('domains.domain_is_booked');
            case 9073:
                return this.translate.t('domains.domain_is_blocked');
            case 9074:
                return this.translate.t('domains.domain_is_expired');
            case 9076:
                return this.translate.t('domains.domain_is_registered');
            case 9077:
                return this.translate.t('domains.domain_is_reserved');
        }

        return null;
    }

    isPromo() {
        if (this.prices === null) {
            return false;
        }

        return this.prices.promo;
    }

    getPrice() {
        if (this.prices === null) {
            return null;
        }

        if (this.isPromo()) {
            return this.prices.promo_price;
        }

        return this.prices.price;
    }
}

let domainCheckPriceComponent = {
    bindings: {
        name: '<',
        extension: '@',
        extensionIdn: '@',
    },
    template: DomanCheckPriceView,
    controller: DomainCheckPriceController
};

DomainCheckPriceController.$inject = ['domainsService', 'translateService'];
export default domainCheckPriceComponent;
