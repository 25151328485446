function translateFilter(translateService, $sce) {
    const f = (text, values) => {
        return $sce.trustAsHtml(translateService.translate(text, values)) || text;
    };

    f.$stateful = true;

    return f;
}

translateFilter.$inject = ['translateService', '$sce'];

export default translateFilter;
