import CookieWallModalView from '../views/cookieWallModal.view.html?raw';

class CookieWallController {
    constructor($scope, $uibModal) {
        this.$scope = $scope;
        this.$uibModal = $uibModal;
    }

    $onInit() {
        this.detectCookies();
        window.addEventListener('showCookieWallModal', () => {
            this.openModal();
        });
    }

    openModal() {
        let modalInstance = this.$uibModal.open({
            animation: 'false',
            size: 'lg',
            controller: CookieWallModalController,
            controllerAs: 'modal',
            template: CookieWallModalView,
            backdrop: 'static',
            keyboard: false,
            resolve: {'modalInstance': () => {
                return {
                    getInstance: () => {
                        return modalInstance;
                    }
                }
            }}
        });
        modalInstance.result.then(() => {}).catch(() => {});
    }

    closeModal() {
        this.$uibModal.close();
    }

    detectCookies() {
        let isSet = this.getCookie('acceptCookies');

        if (!isSet) {
            this.openModal();
        }
    }

    getCookie(cname) {
        let name = cname + "=";
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
}

class CookieWallModalController {
    constructor($scope, modalInstance) {
        this.cookie_1 = true;
        this.cookie_2 = false;
        this.cookie_3 = false;
        this.$scope = $scope;

        this.showMoreCookieInfo = false;
        this.showMoreCookieDetails = false;
        this.$uibModalInstance = modalInstance.getInstance();
    }

    $onInit() {
        let acceptCookies = this.getCookie('acceptCookies');

        if (acceptCookies == 1 || acceptCookies == 2) {
            this.cookie_2 = true;
        }

        if (acceptCookies == 1 || acceptCookies == 3) {
            this.cookie_3 = true;
        }
    }

    acceptCookies() {
        this.setCookie('acceptCookies', '1', 30);

        if (typeof (gtag) === 'function') {
            gtag('consent', 'update', {
                'ad_user_data': 'granted',
                'ad_personalization': 'granted',
                'ad_storage': 'granted',
                'analytics_storage': 'granted'
            });
        }

        if (typeof (fbq) === 'function') {
            fbq('consent', 'grant');
        }

        this.closeModal();
    }

    acceptSomeCookies() {
        if (this.cookie_2 && this.cookie_3) {
            this.setCookie('acceptCookies', '1', 30);

            if (typeof (gtag) === 'function') {
                gtag('consent', 'update', {
                    'ad_user_data': 'granted',
                    'ad_personalization': 'granted',
                    'ad_storage': 'granted',
                    'analytics_storage': 'granted'
                });
            }

            if (typeof (fbq) === 'function') {
                fbq('consent', 'grant');
            }
        } else if (this.cookie_2) {
            this.setCookie('acceptCookies', '2', 30);

            if (typeof (gtag) === 'function') {
                gtag('consent', 'update', {
                    'ad_user_data': 'denied',
                    'ad_personalization': 'denied',
                    'ad_storage': 'denied',
                    'analytics_storage': 'granted'
                });
            }

            if (typeof (fbq) === 'function') {
                fbq('consent', 'revoke');
            }
        } else if (this.cookie_3) {
            this.setCookie('acceptCookies', '3', 30);

            if (typeof (gtag) === 'function') {
                gtag('consent', 'update', {
                    'ad_user_data': 'granted',
                    'ad_personalization': 'granted',
                    'ad_storage': 'granted',
                    'analytics_storage': 'denied'
                });
            }

            if (typeof (fbq) === 'function') {
                fbq('consent', 'grant');
            }
        } else {
            this.setCookie('acceptCookies', '4', 30);

            if (typeof (gtag) === 'function') {
                gtag('consent', 'update', {
                    'ad_user_data': 'denied',
                    'ad_personalization': 'denied',
                    'ad_storage': 'denied',
                    'analytics_storage': 'denied'
                });
            }

            if (typeof (fbq) === 'function') {
                fbq('consent', 'revoke');
            }
        }

        this.closeModal();
    }

    setCookie(cname, cvalue, exdays) {
        let d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    getCookie(cname) {
        let name = cname + "=";
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    setModalInstance(modalInstalce) {
        this.modalInstalce = modalInstalce;
    }

    closeModal() {
        this.$uibModalInstance.dismiss('');
    }
}

let cookieWallComponent = {
    bindings: {},
    controller: CookieWallController
};

CookieWallController.$inject = ['$scope', '$uibModal'];
CookieWallModalController.$inject = ['translateService', 'modalInstance'];

export default cookieWallComponent;
