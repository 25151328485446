export default class FaqController {
    constructor($scope, elasticlunr, markjs) {

        this.$scope = $scope;
        this.foundItems = this.items = [];
        this.searchPhrase = '';
        this.markjs = markjs.default;
        this.elasticlunr = elasticlunr;

        this.$scope.$watch(() => this.items, (newVal, oldVal) => {
            this.initSearch();
        });

        this.$scope.$watch(() => this.searchPhrase, () => {
            this.foundItems = [];
            if (this.searchPhrase) {
                this.search();
            }
        });
    }

    initSearch() {
        this.searchIndex = this.elasticlunr(function () {
            this.addField('answer');
            this.addField('question');
            this.setRef('id');
        });

        this.items.map((item, key) => {
            item.id = key + 1;
            return item;
        });

        this.items.forEach(item => this.searchIndex.addDoc(item));
    }

    search() {
        let searchResults = this.searchIndex.search(this.searchPhrase, {
            fields: {
                question: {boost: 2},
                answer: {boost: 1}
            },
            expand: true,
            bool: 'AND'
        });
        searchResults.forEach(searchResult => {
            this.foundItems.push({...this.items[parseInt(searchResult.ref) - 1]});
        });

        this.highlightSearchTokens();
    }

    highlightSearchTokens() {
        this.foundItems.map(foundItem => {
            let div = document.createElement('div');
            div.innerHTML = foundItem.question;

            let markInstance = new this.markjs(div);
            markInstance.mark(this.searchPhrase);
            foundItem.question = div.innerHTML;

            div = document.createElement('div');
            div.innerHTML = foundItem.answer;

            markInstance = new this.markjs(div);
            markInstance.mark(this.searchPhrase);
            foundItem.answer = div.innerHTML;
        });
    }
}

FaqController.$inject = ['$scope', 'elasticlunr', 'markjs'];
