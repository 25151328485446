export default class SpinnerService {
    constructor() {
        this.spinners = {};
    }

    show(name) {
        if (this.spinners[name]) {
            this.spinners[name].show();
        }
    }

    close(name) {
        if (this.spinners[name]) {
            this.spinners[name].close();
        }
    }

    showAll() {
        for (var name in this.spinners) {
            if (this.spinners[name]) {
                this.spinners[name].show();
            }
        }
    }

    closeAll() {
        for (var name in this.spinners) {
            if (this.spinners[name]) {
                this.spinners[name].close();
            }
        }
    }

    register(name, spinnerContext) {
        this.spinners[name] = spinnerContext;
    }

    unregister(name) {
        this.spinners[name] = null;
    }
}
