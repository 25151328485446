export default class BsTooltipDirective {
    constructor(translateService) {
        this.restrict = 'A'
        this.scope = {
            title: '=?bsTooltip',
            placement: '@bsPlacement',
            html: '@bsTooltipHtml'
        }
        this.tooltip = null;
        this.translate = translateService;
    }

    link(scope, elm) {
        if (!scope.title) {
            return;
        }

        scope.html = scope.html === 'true';
        let params = null;
        let title = '';

        if (typeof (scope.title) === 'object') {
            title = scope.title[0];
            if (typeof (scope.title[1]) !== 'undefined') {
                params = scope.title[1];
            }
        } else {
            title = scope.title;
        }

        this.translate.tp(title, params).then(translatedTitle => {
            let config = {
                title: translatedTitle,
                html: scope.html === 'true',
                timeout: 1000
            };

            if (scope.placement) {
                config.placement = scope.placement;
            }

            elm[0].tooltip = new bootstrap.Tooltip($(elm), config);

            elm.on('click', (e) => {
                setTimeout(() => {
                    if (e.delegateTarget.tooltip) {
                        e.delegateTarget.tooltip.hide();
                    }
                }, 500);
            });
        });
    }
    

    static directiveFactory(translateService) {
        return new BsTooltipDirective(translateService);
    }
}
