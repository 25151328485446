export default class CheckoutController {
    constructor($scope, sharedData) {
        this.$scope = $scope;
        this.sharedData = sharedData;

        this.cartItems = [];
    }

    $onInit() {
        this.cartItems = this.sharedData.cart.items;
    }
}

CheckoutController.$inject = ['$scope', 'sharedData'];
