import MiniCartView from '../views/miniCart.view.html?raw';

class MiniCartController {
    constructor($scope, $document, $element, $location, sharedData) {
        this.$scope = $scope;
        this.$document = $document;
        this.$element = $element;
        this.$location = $location;
        this.sharedData = sharedData;
        this.showCart = false;
    }

    $onInit() {
        const documentClickOutside = (e) => {
            if (this.showCart === false) {
                return;
            }

            if (this.$element !== e.target && !this.$element[0].contains(e.target)) {
                this.showCart = false;

                this.$scope.$apply();
            }
        };

        this.$document.on('click', documentClickOutside);
    }

    $onChanges() {

    }

    remove(item) {
        this.sharedData.cart.remove(item);

        if (this.$location.absUrl().endsWith('/cart')) {
            window.location.pathname = 'cart';
        }

        if (this.$location.absUrl().endsWith('/checkout')) {
            window.location.pathname = 'checkout';
        }
    }
}

let miniCartComponent = {
    bindings: {},
    template: MiniCartView,
    controller: MiniCartController
};

MiniCartController.$inject = ['$scope', '$document', '$element', '$location', 'sharedData'];

export default miniCartComponent;
